import React from "react";
import { Button } from "react-bootstrap";
import HowToPlay from "../components/howToPlay";
import config from "../config.json";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";

function Title() {
  const { t } = useTranslation();

  return (
    <>
      <header class="masthead">
        <div class="container">
          <div class="masthead-subheading">Sort the thoughts!</div>
          <div class="masthead-heading text-uppercase">{config.name}</div>
          <Button
            variant="primary"
            size="xl"
            href={`/${config.name}`}
            className="text-uppercase"
          >
            {t("startButton")}
          </Button>

          <div className="text-decoration-underline mt-sm-2">
            <a href="#howto">{t("howToLink")}</a>
          </div>
        </div>
      </header>
      <HowToPlay />
      <Footer />
    </>
  );
}

export default Title;
