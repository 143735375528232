import emailjs from "@emailjs/browser";
import config from "../config";

function SendEmail(name, number, email) {
  console.log(name);
  var templateParams = {
    to_name: name,
    your_number: number,
    to_email: email,
  };

  emailjs.init(process.env.REACT_APP_EMAILJS_USERID);
  emailjs
    .send(
      process.env.REACT_APP_EMAILJS_SERVICEID,
      process.env.REACT_APP_EMAILJS_TEMPLATEID,
      templateParams
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

export default {
  sendEmail: SendEmail,
};
