import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

function Footer(props) {
  return (
    <footer className="footer py-4">
      <Container>
        <Row className="align-items-center">
          <Col lg={4} className="text-lg-start">
            Copyright &copy; Sincere Technologies 2022
          </Col>
          <Col lg={4} className="my-3 my-lg-0">
            <Button
              variant="dark"
              className="mx-2 btn-social"
              href="https://twitter.com/SincereTech1"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </Button>
            {/* <Button variant="dark" className="mx-2 btn-social">
              <FontAwesomeIcon icon={faFacebookF} />
            </Button>
            <Button variant="dark" className="mx-2 btn-social">
              <FontAwesomeIcon icon={faLinkedin} />
            </Button> */}
          </Col>
          <Col lg={4} className="text-lg-end">
            <a
              className="link-dark text-decoration-none me-3"
              href="https://sinceretechnologie.wixsite.com/website/privacypolicy"
            >
              Privacy Policy
            </a>
            {/* <a className="link-dark text-decoration-none" href="#!">
              Terms of Use
            </a> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
