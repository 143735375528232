import React from "react";
import { Image } from "react-bootstrap";

import ImageSrc1 from "../images/howtoplay/1.jpg";
import ImageSrc2 from "../images/howtoplay/2.jpg";
import ImageSrc3 from "../images/howtoplay/3.jpg";
import ImageSrc4 from "../images/howtoplay/4.jpg";
import { useTranslation } from "react-i18next";

function HowToPlay() {
  const { t } = useTranslation();

  return (
    <section className="page-section" id="howto">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">How To Play</h2>
          <h3 className="section-subheading text-muted">It is easy!</h3>
        </div>
        <ul className="timeline">
          <li>
            <div className="timeline-image">
              <Image src={ImageSrc1} roundedCircle fluid="true" />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>1</h4>
                <h4 className="subheading">{t("description.title1")}</h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">{t("description.part1")}</p>
              </div>
            </div>
          </li>
          <li className="timeline-inverted">
            <div className="timeline-image">
              <Image src={ImageSrc2} roundedCircle fluid="true" />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>2</h4>
                <h4 className="subheading">{t("description.title2")}</h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">
                  {t("description.part2.line1")}
                  <ul>
                    <li>{t("description.part2.operation1")}</li>
                    <li>{t("description.part2.operation2")}</li>
                    <li>{t("description.part2.operation3")}</li>
                    <li>{t("description.part2.operation4")}</li>
                    <li>{t("description.part2.operation5")}</li>
                  </ul>
                  {t("description.part2.line2")}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div className="timeline-image">
              <Image src={ImageSrc3} roundedCircle fluid="true" />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>3</h4>
                <h4 className="subheading">{t("description.title3")}</h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">
                  {t("description.part3")}
                  {/* Discuss about who has the smallest number and biggest number.
                  Sort the player's name, and press the button when ready. */}
                </p>
              </div>
            </div>
          </li>
          <li className="timeline-inverted">
            <div className="timeline-image">
              <Image src={ImageSrc4} roundedCircle fluid="true" />
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h4>4</h4>
                <h4 className="subheading">{t("description.title4")}</h4>
              </div>
              <div className="timeline-body">
                <p className="text-muted">{t("description.part4")}</p>
              </div>
            </div>
          </li>
          <li className="timeline-inverted">
            <div className="timeline-image">
              <h4>
                Play
                <br />
                the Game
                <br />
                Again!
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default HowToPlay;
