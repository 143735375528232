import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function ModalListItem({ name, number, guess, seikai }) {
  const correction = seikai ? (
    <FontAwesomeIcon icon={faCheck} size="2x" color="green" />
  ) : (
    <FontAwesomeIcon icon={faTimes} size="2x" color="white" />
  );
  return (
    <Row className=" justify-content-center align-items-center">
      {/* <Col xs={1} sm={1} md={1} lg={1}>
        <IconColumn icon={icon} />
      </Col> */}
      <Col xs={2} sm={2} md={2} lg={2} className="fs-6 ">
        {name}
      </Col>
      <Col className="fs-6" xs={7} sm={7} md={7} lg={7} xl={7}>
        {guess}
      </Col>
      <Col className="text-center" xs={1} sm={1} md={1} lg={1}>
        {number}
      </Col>
      <Col xs={1} sm={1} md={1} lg={1}>
        {correction}
      </Col>
    </Row>
  );
}

export default ModalListItem;
