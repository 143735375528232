import React from "react";
import "./css/App.css";
import config from "./config.json";
import NavBar from "./components/navBar";
import Title from "./screen/TitleScreen";
import Game from "./screen/GameScreen";
import Footer from "./components/footer";
import { Switch, Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ogp from "./components/twitterOgp";

function App() {
  return (
    <React.Fragment>
      <Ogp />
      <ToastContainer />
      <NavBar />
      <main>
        <Switch>
          <Route path="/index" component={Title} />
          <Route path={`/${config.name}`} component={Game} />
          <Redirect from="/" exact to="/index" />
        </Switch>
      </main>
    </React.Fragment>
  );
}

export default App;
