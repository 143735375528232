import React, { useState } from "react";
import { Button } from "react-bootstrap";
import GuessListItem from "./guessListItem";
import { useTranslation } from "react-i18next";

function ShareScreen({ members, onSubmit, theme }) {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState("Done with the memo?");
  const [guessList, setGuessList] = useState(members);
  const [memberList, setMemberList] = useState(members);

  // const handleChange = (list) => {
  //   setGuessList(list);
  //   console.log(memberList);
  // };
  const handleChange = (key, event) => {
    guessList[key].guess = event.target.value;
    setGuessList(guessList);
  };

  return (
    <div className="text-center">
      <h3 className="section-heading">
        {t("header4-1")}
        {theme}
        {t("header4-2")}
      </h3>
      <h3 className="text-muted section-subheading">
        あなたの数字の大きさを表す 『{theme}』 を、他の人に伝えましょう (100:
        テーマの中で最大のものやスゴイもの ⇒ 1:
        テーマの中で最少のものやすごくないもの)
      </h3>
      {/* <FlatList
        data={memberList}
        inputField={true}
        onChange={(list) => handleChange(list)}
      /> */}
      {memberList.map((row, key) => (
        <GuessListItem
          name={row.name}
          icon={row.icon}
          key={key}
          onChange={(event) => handleChange(key, event)}
        />
      ))}
      <Button
        variant="primary"
        size="xl"
        className="text-uppercase"
        onMouseEnter={() => {
          setButtonText("Done with the memo");
        }}
        onMouseLeave={() => {
          setButtonText("Done with the memo?");
        }}
        onClick={() => onSubmit(guessList)}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default ShareScreen;
