import http from "./httpService";

function sendToWebex(email, number, name) {
  const data = {
    email,
    name,
    number,
  };

  // const param = {
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  console.log(data);
  // console.log(param);
  try {
    http.post("/webex", data);
    // const res = http.get(config.apiUrl + "/");
    // console.log(res);
  } catch (error) {
    console.log(error.response);
  }
}

function sendToSlack(email, number, name) {
  const data = {
    email,
    name,
    number,
  };

  try {
    http.post("/slack", data);
  } catch (error) {
    console.log(error.response);
  }
}

export default {
  slack: sendToSlack,
  webex: sendToWebex,
};
