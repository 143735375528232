import React, { useState } from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import IconColumn from "./iconColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

function OrderListItem({ index, icon, name, guess = "", onBlur }) {
  const [edit, setEdit] = useState(false);
  const [guessText, setGuessText] = useState(guess);

  const handleChange = (event) => {
    setGuessText(event.target.value);
  };

  const handleEdit = () => {
    setEdit(false);
    console.log(index);
    console.log(guessText);
    onBlur(index, guessText);
  };

  return (
    <Row className="mb-3 justify-content-center align-items-center">
      <Col
        xs="auto"
        sm="auto"
        md="auto"
        lg="auto"
        className="align-items-center"
      >
        <span className="fa-stack fa-lg">
          <FontAwesomeIcon
            icon={faGripLines}
            size="1x"
            className="fa-stack-1x text-secondary"
          />
        </span>
      </Col>
      <Col xs="auto" sm="auto" md="auto" lg="auto">
        <IconColumn icon={icon} />
      </Col>
      <Col xs="3" sm="4" md="3" lg="3" className="fs-5 text-truncate">
        {name}
      </Col>
      <Col xs="3" sm="4" md="4" lg="5" className="text-truncate">
        {edit ? (
          <InputGroup className="mb-3">
            <FormControl
              aria-label="Username"
              aria-describedby="basic-addon1"
              onBlur={() => handleEdit()}
              onChange={handleChange}
              value={guessText}
            />
          </InputGroup>
        ) : (
          <text onClick={() => setEdit(true)}>{guessText}</text>
        )}
      </Col>
    </Row>
  );
}

export default OrderListItem;
