import React from "react";
import ModalListItem from "./modalListItem";
import { Modal, Button } from "react-bootstrap";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

function ResultModal({ theme, resultList, show, onHide, correct, modalTitle }) {
  const handleImageButton = () => {
    var node = document.getElementById("to-image");
    console.log(node);
    htmlToImage
      .toJpeg(node)
      .then(function (dataUrl) {
        download(dataUrl, "sortful.jpg");
        var img = new Image();
        img.src = dataUrl;
        // document.body.appendChild(img);
        // setShareImage(dataUrl);
        // node.appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <Modal show={show} onHide={onHide} keyboard={false}>
      <Modal.Header closeButton>
        {/* <Modal.Title>Would you like to share this?</Modal.Title> */}
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body id="to-image" className="bg-white">
        <h5 className="text-center">
          <i>{correct}</i>
        </h5>
        <h6 className="text-center">[{theme}]</h6>
        {resultList.map((item) => (
          <ModalListItem
            name={item.name}
            // icon={item.icon}
            guess={item.guess}
            number={item.number}
            seikai={item.seikai}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleImageButton()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResultModal;
