import React from "react";
import { Row, Col } from "react-bootstrap";
import IconColumn from "./iconColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function AnswerListItem({ icon, name, number, seikai }) {
  const correction = seikai ? (
    <FontAwesomeIcon icon={faCheck} size="2x" color="green" />
  ) : (
    <FontAwesomeIcon icon={faTimes} size="2x" color="white" />
  );
  return (
    <Row className="mb-3 justify-content-center align-items-center">
      <Col xs={1} sm={1} md={1} lg={1}>
        {correction}
      </Col>
      <Col xs="auto" sm="auto" md="auto" lg="auto">
        <IconColumn icon={icon} />
      </Col>
      <Col
        xs="5"
        sm="5"
        md="5"
        lg="5"
        className="fs-3 text-truncate text-start"
      >
        {name}
      </Col>
      <Col className="fs-3 text-truncate">{number}</Col>
    </Row>
  );
}

export default AnswerListItem;
