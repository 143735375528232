import React from "react";
import { categoryListJP, categoryList } from "../config/categories";
import { Form, Col, Button, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function SetCategory({ onSubmit, pastCategoryList }) {
  const { t, i18n } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const selectedCategoryList =
    i18n.language === "ja" ? categoryListJP : categoryList;

  console.log(pastCategoryList);
  return (
    <div>
      <div className="text-center">
        <h2 className="section-heading text-uppercase">{t("header2")}</h2>
        <h3 className="section-subheading text-muted">{t("header2")}</h3>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Row xs={1} md={2}>
            {selectedCategoryList.map((c, index) => (
              <Col md={3}>
                {pastCategoryList ? (
                  <Form.Check
                    name="category"
                    type="checkbox"
                    id={c.id}
                    key={index}
                    value={c.id}
                    label={c.name}
                    inline
                    {...register("category")}
                    defaultChecked={pastCategoryList.includes(c.id.toString())}
                  />
                ) : (
                  <Form.Check
                    name="category"
                    type="checkbox"
                    id={c.id}
                    key={index}
                    value={c.id}
                    label={c.name}
                    inline
                    {...register("category")}
                    defaultChecked
                  />
                )}
              </Col>
            ))}
          </Row>
        </Form.Group>
        <div className="text-center">
          <Button variant="primary" type="submit" size="xl" className="mb-3">
            {t("categorybutton")}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default SetCategory;
