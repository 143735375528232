import {
  faAppleAlt,
  faLemon,
  faCarrot,
  faLeaf,
  faGhost,
  faCat,
  faDog,
  faCrow,
  faDove,
  faDragon,
  faKiwiBird,
  faSpider,
  faFish,
  faHorse,
  faFrog,
  faHippo,
  faCircle,
  faEnvelope,
  faPlus,
  faTimes,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons";
import { faSlack } from "@fortawesome/free-brands-svg-icons";

export const icons = {
  APPLE: faAppleAlt,
  LEMON: faLemon,
  CARROT: faCarrot,
  LEAF: faLeaf,
  GHOST: faGhost,
  CAT: faCat,
  DOG: faDog,
  CROW: faCrow,
  DOVE: faDove,
  DRAGON: faDragon,
  KIWI: faKiwiBird,
  SPIDER: faSpider,
  FISH: faFish,
  HORSE: faHorse,
  FROG: faFrog,
  HIPPO: faHippo,
  CIRCLE: faCircle,
  MAIL: faEnvelope,
  SLACK: faSlack,
  PLUS: faPlus,
  TIMES: faTimes,
  DOWNLOAD: faFileDownload,
  EMAIL: faEnvelope,
};

export const memberIcons = {
  APPLE: faAppleAlt,
  LEMON: faLemon,
  CARROT: faCarrot,
  LEAF: faLeaf,
  GHOST: faGhost,
  CAT: faCat,
  DOG: faDog,
  CROW: faCrow,
  DOVE: faDove,
  DRAGON: faDragon,
  KIWI: faKiwiBird,
  SPIDER: faSpider,
  FISH: faFish,
  HORSE: faHorse,
  FROG: faFrog,
  HIPPO: faHippo,
};

export function getIcons(number) {
  const iconList = Object.keys(memberIcons).map((k) => k);
  const getIconList = iconList
    .slice()
    .sort(function () {
      return Math.random() - 0.5;
    })
    .slice(0, number);

  return getIconList;
}
