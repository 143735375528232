import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import YourGuessListItem from "./yourGuessListItem";
import { useTranslation } from "react-i18next";
import { useSprings, animated } from "@react-spring/web";
import AnswerListItem from "./answerListItem";
import Confetti from "react-confetti";
import ResultModal from "./resultModal";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

function Result({ theme, newOrderList, answerList, onSubmit }) {
  const { t } = useTranslation();
  const [header, setHeader] = useState(t("header6"));
  const [subheader, setSubheader] = useState(t("subheader6"));
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState(false);
  var correctCount = 0;
  var itemList = [];
  var resultList = [];

  answerList.map((answer, index) => {
    let seikai =
      parseInt(newOrderList[index].number) === parseInt(answer.number);
    itemList.push(
      <AnswerListItem
        key={answer.key}
        name={answer.name}
        icon={answer.icon}
        number={answer.number}
        seikai={seikai}
      />
    );
    newOrderList[index].seikai = seikai;
    if (seikai) correctCount++;
  });

  const [items, setItems] = useState(itemList);

  // const styles = useSpring({
  //   to: { opacity: 1 },
  //   from: { opacity: 0 },
  // });

  const springs = useSprings(
    items.length,
    items.map((item, index) => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: index * 2000,
      onRest:
        index === items.length - 1 ? () => onRestDo() : console.log(index),
    }))
  );

  console.log(resultList);

  // const trail = useTrail(items.length, {
  //   config: { duration: 3000 },
  //   opacity: 1,
  //   from: { opacity: 0 },
  //   onRest: () => {
  //     setTimeout(onRestDo, 2000);
  //   },
  // });

  const onRestDo = () => {
    let r = correctCount === items.length;
    setResult(r);
    const percentage = Math.floor((correctCount / items.length) * 100);

    var header = "";
    if (percentage === 100) {
      header = t("result100");
    } else if (percentage >= 80) {
      header = t("result80");
    } else if (percentage >= 50) {
      header = t("result50");
    } else if (percentage === 0) {
      header = t("result0");
    } else {
      header = t("result10");
    }
    setHeader(header);
    setSubheader(t("subheader7-1") + percentage + t("subheader7-2"));

    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="text-center">
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        hidden={!result}
      />
      <ResultModal
        theme={theme}
        resultList={newOrderList}
        show={showModal}
        onHide={handleClose}
        correct={subheader}
        modalTitle={t("modaltitle")}
      />
      <div>
        <h2 className="section-heading">{header}</h2>
        <h3 className="section-subheading mb-1">{subheader}</h3>
      </div>

      <Row className="text-center mb-3">
        <Col lg="6">
          <div className="result-list text-center">
            <h4 className="my-3 text-decoration-underline">
              {t("subheader6-2")}
            </h4>
            {newOrderList.map((m, index) => (
              <YourGuessListItem
                key={index}
                name={m.name}
                icon={m.icon}
                guess={m.guess}
              />
            ))}
            {/* <FlatList
              data={newOrderList}
              numberField={false}
              guessField={true}
            /> */}
          </div>
        </Col>
        <Col lg="6">
          <div className="result-list ps-3">
            <h4 className="my-3 text-decoration-underline">
              {t("subheader6-1")}
            </h4>
            {/* {answerList.map((m) => (
              <ResultButton
                key={m.key}
                name={m.name}
                icon={m.icon}
                number={m.number}
              />
            ))} */}
            {/* {trail.map(({ ...style }, index) => (
              <animated.div style={style}>
                <animated.div>{items[index]}</animated.div>
              </animated.div>
            ))} */}
            {springs.map((styles, index) => (
              <animated.div style={styles}>{items[index]}</animated.div>
            ))}
          </div>
        </Col>

        {/* <Col lg="1">
          <h5 className="my-3 text-decoration-underline">結果</h5>
          <Row>
            {resultSprings.map((styles, index) => (
              <animated.div style={styles}>
                <Col>{resultList[index]}</Col>
              </animated.div>
            ))}
          </Row>
        </Col> */}
      </Row>
      <Button onClick={() => onSubmit()}>Play again?</Button>
      <p>
        Share this game to your friends:
        <TwitterShareButton
          // beforeOnClick={() => handleShare()}
          title="あなたの正当率は40%です。"
          url="https://sinceretech.app/"
          hashtags={["SORTFUL"]}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <FacebookShareButton url="https://sinceretech.app/">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </p>
    </div>
  );
}

export default Result;
