import React, { useRef, useState } from "react";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import swap from "lodash-move";
import { clamp } from "lodash";
import { Button } from "react-bootstrap";
import OrderListItem from "./orderListItem";
import { useTranslation } from "react-i18next";

const fn =
  (order, active = false, originalIndex = 0, curIndex = 0, y = 0) =>
  (index) =>
    active && index === originalIndex
      ? {
          y: curIndex * 70 + y,
          scale: 1.1,
          zIndex: 1,
          shadow: 15,
          immediate: (key) => key === "y" || key === "zIndex",
        }
      : {
          y: order.indexOf(index) * 70,
          scale: 1,
          zIndex: 0,
          shadow: 1,
          immediate: false,
        };

function TurnOrder({ memberList, onSubmit, theme }) {
  const onGuessChange = (index, guess) => {
    memberList[index].guess = guess;
    console.log(memberList);
  };

  var items = [];
  memberList.map((m, key) => {
    items.push(
      <OrderListItem
        key={key}
        name={m.name}
        icon={m.icon}
        guess={m.guess}
        index={key}
        onBlur={onGuessChange}
      />
    );
  });

  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState("Is this your answer?");
  const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
  const [springs, api] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curRow = clamp(
      Math.round((curIndex * 70 + y) / 70),
      0,
      items.length - 1
    );
    const newOrder = swap(order.current, curIndex, curRow);
    api.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) order.current = newOrder;
  });

  const handleSubmit = () => {
    const newOrder = order.current;
    var newOrderList = [];

    for (var i = 0; i < newOrder.length; i++) {
      newOrderList.push(memberList[newOrder[i]]);
    }

    onSubmit(newOrderList);
  };

  return (
    <div className="text-center">
      <h3 className="section-heading text-uppercase text-decoration-underline">
        {theme}
      </h3>
      <h3 className="text-muted section-subheading">{t("subheader5")}</h3>

      <div className="druggable-container">
        <div className="druggable-order" style={{ height: items.length * 70 }}>
          {springs.map(({ zIndex, shadow, y, scale }, i) => (
            <animated.div
              {...bind(i)}
              key={i}
              style={{
                zIndex,
                boxShadow: shadow.to(
                  (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                ),
                y,
                scale,
              }}
              children={items[i]}
            />
          ))}
        </div>
      </div>

      <Button
        className=""
        onClick={() => handleSubmit()}
        onMouseEnter={() => {
          setButtonText("This is our answer!");
        }}
        onMouseLeave={() => {
          setButtonText("Is this your answer?");
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default TurnOrder;
