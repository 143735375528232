import React, { useState } from "react";
import { InputGroup, Button, FormControl, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../config/icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import messageService from "../services/messageService";
import sendEmail from "../services/sendEmail";

function InputListItem({
  index,
  name = "",
  email = "",
  number,
  onReady,
  onDelete,
  hidden,
}) {
  const [ready, setReady] = useState(false);
  const [sent, setSent] = useState(false);
  const [state, setState] = useState({ userName: name, userEmail: email });
  const style = hidden ? { display: "none" } : {};

  const handleChange = (e) => {
    state[e.target.name] = e.target.value;
    setState(state);
  };

  const handleWebex = async () => {
    if (validateStates()) {
      await messageService.webex(state.userEmail, number, state.userName);
      setSent(true);
    }
  };

  const handleSlack = async () => {
    if (validateStates()) {
      await messageService.slack(state.userEmail, number, state.userName);
      setSent(true);
    }
  };

  const handleEmail = async () => {
    if (validateStates()) {
      await sendEmail.sendEmail(state.userName, number, state.userEmail);
      setSent(true);
    }
  };

  const validateStates = () => {
    let validate = true;

    if (state.userName === "") {
      toast.error("Name is required");
      validate = false;
    }
    if (state.userEmail === "") {
      toast.error("Email is required");
      validate = false;
    }

    return validate;
  };

  const handleReady = () => {
    setReady(true);
    const memberData = {
      name: state.userName,
      email: state.userEmail,
      number: number,
    };
    onReady(memberData);
  };

  return (
    <Row
      className="mb-3 justify-content-center align-items-center"
      key={number}
      style={style}
    >
      {!ready ? (
        <>
          <Col
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            onClick={() => onDelete(index)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Col>
          <Col xs lg="3">
            <InputGroup className="" hasValidation>
              <FormControl
                type="text"
                name="userName"
                placeholder="Name"
                aria-label="name"
                defaultValue={state.userName}
                required
                onChange={handleChange}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="">
              <FormControl
                type="text"
                placeholder="Email"
                aria-label="email"
                defaultValue={state.userEmail}
                required
                name="userEmail"
                onChange={handleChange}
              />

              <Button
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => handleWebex()}
              >
                <div className="webex"></div>
              </Button>
              <Button
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => handleSlack()}
              >
                <FontAwesomeIcon
                  icon={icons.SLACK}
                  size="sm"
                  className="text-secondary"
                />
              </Button>
              <Button
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => handleEmail()}
              >
                <FontAwesomeIcon
                  icon={icons.EMAIL}
                  size="sm"
                  className="text-secondary"
                />
              </Button>
            </InputGroup>
          </Col>
          <Col md="auto">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => handleReady()}
              disabled={!sent}
            >
              ready?
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col md="auto">{state.userName}</Col>
          <Col md="auto">Please wait for other members to join...</Col>
        </>
      )}
    </Row>
  );
}

export default InputListItem;
