import React from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import IconColumn from "./iconColumn";

function GuessListItem({ name, icon, onChange }) {
  return (
    <Row className="mb-3 justify-content-center align-items-center">
      <Col xs="auto" sm="auto" md="auto" lg="auto">
        <IconColumn icon={icon} />
      </Col>
      <Col xs="auto" sm="auto" lg="auto" className="fs-4">
        {name}
      </Col>
      <Col>
        <InputGroup>
          <FormControl
            aria-label="Guess"
            onChange={(event) => onChange(event)}
            placeholder="memo"
          />
        </InputGroup>
      </Col>
    </Row>
  );
}

export default GuessListItem;
