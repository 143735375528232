import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../config/icons";

function IconColumn({ icon, circle = true }) {
  return (
    <span className="fa-stack fa-lg">
      {circle && (
        <FontAwesomeIcon
          icon={icons.CIRCLE}
          size="2x"
          className="fa-stack-1x text-secondary"
        />
      )}
      <FontAwesomeIcon
        icon={icons[icon]}
        size="1x"
        inverse={circle}
        className="fa-stack-1x"
      />
    </span>
  );
}

export default IconColumn;
