import React from "react";
import Helmet from "react-helmet";

import {
  appTitle,
  appUrl,
  appDescription,
  appImageUrl,
} from "../config/app-config.js";

import imageUrl from "../images/logo-big.png";

export default function Ogp({ isRoot, description }) {
  const type = isRoot ? "website" : "article";

  return (
    <Helmet
      title="SORTFUL"
      meta={[
        { name: "twitter:card", content: "summary" },
        { property: "og:image", content: imageUrl },
        { property: "og:title", content: "SORTFUL" },
        {
          property: "og:description",
          content:
            "Sort your thoughts!10分ほどでできるチームビルディング等に最適なゲームです.4~12人推奨",
        },
        { property: "og:url", content: "https://sinceretech.app/" },
      ]}
    />
  );
}
// <meta name="twitter:card" content="summary" />
// <meta property="og:title" content="SORTFUL" />
// <meta
//   property="og:description"
//   content="Sort your thoughts!10分ほどでできるチームビルディング等に最適なゲームです.4~12人推奨"
// />
// <meta property="og:url" content="https://sinceretech.app/sortful/" />
// <meta property="twitter:image" content={imageUrl} />
