import React from "react";
import { Row, Col } from "react-bootstrap";
import IconColumn from "./iconColumn";

function YourGuessListItem({ name, guess = "", icon }) {
  // const cutText = guess.length > 8 ? guess.slice(0, 8) + "..." : guess;
  return (
    <Row className="mb-3 justify-content-center align-items-center">
      <Col xs="3" sm="3" md="3" lg="3">
        <IconColumn icon={icon} />
      </Col>
      <Col
        xs="4"
        sm="4"
        md="4"
        lg="4"
        className="fs-4 text-truncate text-start"
      >
        {name}
      </Col>
      <Col xs="5" sm="5" md="5" lg="5 text-truncate">
        {guess}
      </Col>
    </Row>
  );
}

export default YourGuessListItem;
