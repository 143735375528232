import React, { useState } from "react";
import ShareScreen from "../components/share";
import TurnOrder from "../components/turnOrder";
import { ListGroup, Card } from "react-bootstrap";
import AdsCard from "../components/AdsCard";
import Result from "../components/result";
import { getTheme } from "../config/categories";
import SetCategory from "../components/setCategory";
import ThemeScreen from "../components/themeScreen";
import AddMember from "./../components/addMember";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// const sampleAnswerList = [
//   { key: 2, name: "Bob", icon: "DOG", guess: "elephant", number: 25 },
//   { key: 3, name: "Kate", icon: "CROW", guess: "sparrow", number: 10 },
//   { key: 1, name: "John", icon: "CAT", guess: "cat", number: 14 },
//   { key: 4, name: "Tom", icon: "HIPPO", guess: "dog", number: 90 },
//   { key: 5, name: "Kate", icon: "HIPPO", guess: "dog", number: 80 },
// ];
// const sampleOrderList = [
//   { key: 3, name: "Kate", icon: "CROW", guess: "sparrow", number: 10 },
//   { key: 1, name: "John", icon: "CAT", guess: "cat", number: 14 },
//   {
//     key: 2,
//     name: "Bob",
//     icon: "DOG",
//     guess:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur voluptate pariatur incidunt facilis eveniet quis tenetur modi maiores dolore nobis.",
//     number: 25,
//   },
//   { key: 5, name: "Kate", icon: "HIPPO", guess: "dog", number: 80 },
//   { key: 4, name: "Tom", icon: "HIPPO", guess: "dog", number: 90 },
// ];

function Game() {
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  // const [hostName, setHostName] = useState("");
  const [page, setPage] = useState("addMember");
  const [newOrderList, setNewOrderList] = useState();
  const [orderdList, setOrderedList] = useState();
  const [categoryIdList, setCategoryIdList] = useState();
  const [theme, setTheme] = useState();

  const history = useHistory();

  const pastMemberList = JSON.parse(sessionStorage.getItem("memberList"));
  const pastCategoryList = JSON.parse(sessionStorage.getItem("categoryList"));

  // const handleSetNumber = (data) => {
  //   setNumParticipant(data);
  //   setPage("join");
  // };

  const handleSetCategory = (data) => {
    const themekouho = getTheme(data.category);
    setCategoryIdList(data.category);
    sessionStorage.setItem("categoryList", JSON.stringify(data.category));
    setTheme(themekouho);
    setPage("theme");
  };

  const handleTheme = (theme) => {
    setTheme(theme);
    console.log(memberList);
    setPage("share");
  };

  // const handleSetting = (data) => {
  //   console.log(data);
  //   setNumParticipant(data.numParticipant);
  //   setHostName(data.hostName);
  //   setTheme(getTheme(data.category));
  //   setPage("join");
  // };

  const sortNumbers = (list) => {
    let result = list.sort(function (a, b) {
      return a.number < b.number ? 1 : -1;
    });
    console.log(result);
    setOrderedList(result);
  };

  const handleJoin = (data) => {
    setMemberList(data);
    const sortList = data.slice(0, data.length);
    sortNumbers(sortList);
    setPage("category");
  };

  const handleShare = (data) => {
    setMemberList(data);
    setPage("order");
  };

  const handleOrder = (data) => {
    console.log(data);
    console.log(memberList);
    setNewOrderList(data);
    setPage("result");
  };

  const handleResult = () => {
    //play againの処理
    //クリアする値⇒memberのnumber、テーマ。
    //メンバーの名前、emailアドレスは保存のまま、カテゴリーリストも保持。
    history.go();
  };

  // const pickNumbers = (min, max, n) => {
  //   const list = new Array(max - min + 1).fill().map((_, i) => i + min);
  //   const ret = [];
  //   while (n--) {
  //     const rand = Math.floor(Math.random() * (list.length + 1)) - 1;
  //     console.log(rand);
  //     ret.push(...list.splice(rand, 1));
  //   }
  //   return ret;
  // };

  return (
    <section className="page-section">
      <div className="row main container">
        <div className="side-menu  col-3">
          <Card>
            <Card.Header as="h5">How to play</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item className="how-to" active={page === "addMember"}>
                {t("howtoplay1")}
              </ListGroup.Item>
              <ListGroup.Item
                className="how-to"
                active={page === "theme" || page === "category"}
              >
                {t("howtoplay2")}
              </ListGroup.Item>
              <ListGroup.Item className="how-to" active={page === "share"}>
                {t("howtoplay3")}
              </ListGroup.Item>
              <ListGroup.Item className="how-to" active={page === "order"}>
                {t("howtoplay4")}
              </ListGroup.Item>
              <ListGroup.Item className="how-to" active={page === "result"}>
                {t("howtoplay5")}
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <AdsCard />
        </div>
        <div className="container game-screen">
          {page === "addMember" && (
            <AddMember
              onSubmit={(data) => handleJoin(data)}
              pastMemberList={pastMemberList}
            />
          )}

          {/* {page === "numPlayer" && (
            <SetNumPlayer onSubmit={(data) => handleSetNumber(data)} />
          )}
          {page === "join" && (
            <Join
              number={numParticipant}
              numberList={pickNumbers(1, 100, numParticipant)}
              onSubmit={(data) => handleJoin(data)}
            />
          )} */}
          {page === "category" && (
            <SetCategory
              onSubmit={(data) => handleSetCategory(data)}
              pastCategoryList={pastCategoryList}
            />
          )}
          {page === "theme" && (
            <ThemeScreen
              theme={theme}
              onSubmit={(data) => handleTheme(data)}
              categoryIdList={categoryIdList}
            />
          )}
          {/* {page === "setting" && (
            <Setting onSubmit={(data) => handleSetting(data)} />
          )} */}
          {page === "share" && (
            <ShareScreen
              members={memberList}
              onSubmit={(data) => handleShare(data)}
              theme={theme.name}
            />
          )}
          {page === "order" && (
            <TurnOrder
              memberList={memberList}
              onSubmit={(list) => handleOrder(list)}
              theme={theme.name}
            />
          )}
          {page === "result" && (
            <Result
              theme={theme.name}
              newOrderList={newOrderList}
              answerList={orderdList}
              onSubmit={handleResult}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Game;
