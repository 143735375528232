import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getTheme } from "../config/categories";
import { useTranslation } from "react-i18next";

function ThemeScreen({ theme, onSubmit, categoryIdList }) {
  const { t } = useTranslation();
  const [changeTheme, setChangeTheme] = useState(theme);

  const handleChange = () => {
    setChangeTheme(getTheme(categoryIdList));
  };

  return (
    <div className="text-center">
      <h3 className="mb-3">{t("header3")}</h3>
      <h1 className="mb-3">{changeTheme.name}</h1>
      <Row className="align-items-center">
        <Col>
          <Button variant="primary" size="lg" onClick={() => handleChange()}>
            {t("themechangebutton")}
          </Button>
        </Col>
        <Col>
          <Button
            variant="primary"
            size="xl"
            onClick={() => onSubmit(changeTheme)}
          >
            OK!
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default ThemeScreen;
