export const categoryList = [
  { id: 1, name: "Business" },
  { id: 2, name: "Technology" },
  { id: 3, name: "Nature" },
  { id: 4, name: "Food" },
  { id: 5, name: "Something ~~" },
  { id: 6, name: "World" },
  { id: 7, name: "Food" },
  { id: 8, name: "Daily Life" },
];

export const categoryListJP = [
  { id: 1, name: "ビジネス" },
  { id: 2, name: "テクノロジー" },
  { id: 3, name: "自然" },
  { id: 4, name: "食べ物" },
  { id: 5, name: "〇〇なもの" },
  { id: 6, name: "世界" },
  { id: 7, name: "趣味" },
  { id: 8, name: "生活" },
];

export const themeList = [
  { id: 1, groupId: 1, name: "Cool Job" },
  { id: 2, groupId: 3, name: "Big Creature" },
  { id: 3, groupId: 5, name: "Cool Family Name" },
  { id: 4, groupId: 5, name: "Something Cool" },
  { id: 5, groupId: 5, name: "Something Kawaii" },
  { id: 6, groupId: 7, name: "Cool lines (phrases)" },
  { id: 7, groupId: 5, name: "MFD (Marked For Death)" },
  {
    id: 8,
    groupId: 2,
    name: "Future technology which increases productivity",
  },
  { id: 9, groupId: 4, name: "Best nibbles for beer" },
  { id: 10, groupId: 3, name: "Cute Animal" },
  { id: 11, groupId: 8, name: "Celebrity you want to marry" },
  { id: 12, groupId: 5, name: "Great present from Santa" },
  { id: 13, groupId: 4, name: "Best sweets to have from customer" },
  { id: 14, groupId: 4, name: "Best for dessert" },
  { id: 15, groupId: 5, name: "One thing you can bring to deserted island" },
  { id: 16, groupId: 5, name: "Celebrity to bring to deserted island" },
  { id: 17, groupId: 6, name: "Country to travel" },
  { id: 18, groupId: 4, name: "Best ingredients for sandwiches" },
  { id: 19, groupId: 4, name: "炊き込みご飯の人気具材" },
  { id: 20, groupId: 2, name: "Things you want to do in Metaverse" },
  { id: 21, groupId: 5, name: "What you want to do with Jedi force power" },
  { id: 22, groupId: 4, name: "テンションの上がる今晩のおかず" },
  {
    id: 23,
    groupId: 5,
    name: "If I had another life I want to be _____(someone famous)",
  },
  {
    id: 24,
    groupId: 3,
    name: "If I had another life I want to be _____(animal)",
  },
  { id: 25, groupId: 3, name: "Animals you want to transform" },
  { id: 26, groupId: 6, name: "Things you should do when visiting Japan" },
  { id: 27, groupId: 6, name: "Things you should do when visiting Hawaii" },
  { id: 28, groupId: 2, name: "Something Alexa is desired to do" },
  { id: 29, groupId: 5, name: "Something freak you out" },
  { id: 30, groupId: 5, name: "Things make you relax" },
  { id: 31, groupId: 5, name: "Shocking thing your parents revealed" },
  { id: 32, groupId: 4, name: "Foods you come up with SOUR" },
  { id: 33, groupId: 5, name: "Exciting package Amazon sent to you" },
  {
    id: 34,
    groupId: 1,
    name: "Skill or qualification you can brag if you had one",
  },
  { id: 35, groupId: 1, name: "どこでも転職できるとしたら転職したい企業" },
  { id: 36, groupId: 1, name: "Desired career when you were child" },
  { id: 37, groupId: 7, name: "Something helpful to defeat zombies" },
  { id: 38, groupId: 7, name: "Tough and powerful animation character" },
  { id: 39, groupId: 7, name: "Cool name" },
  {
    id: 40,
    groupId: 6,
    name: "Something helpful to have in the middle of desert",
  },
  {
    id: 41,
    groupId: 6,
    name: "Something helpful to have in the middle of North Pole",
  },
  { id: 42, groupId: 5, name: "something powerful you can express in 3 words" },
  { id: 43, groupId: 5, name: "Something scary with 5 letters" },
  { id: 44, groupId: 5, name: "Something cute with 4 letters" },
  { id: 45, groupId: 5, name: "Something happy with 2 words" },
  {
    id: 46,
    groupId: 8,
    name: "Something suprises you when you open the front door",
  },
  {
    id: 47,
    groupId: 1,
    name: "Some traits that makes he/she is thought to be competent",
  },
  { id: 48, groupId: 2, name: "A new feature that the iPhone 99 will have" },
  {
    id: 49,
    groupId: 5,
    name: "Outrageous thing that a great thief have stolen",
  },
  {
    id: 50,
    groupId: 8,
    name: "Something nice to have on the ground floor of the apartment building you live in",
  },
  {
    id: 51,
    groupId: 2,
    name: "A new feature that would be great to have in a robot vacuum cleaner",
  },
  {
    id: 52,
    groupId: 8,
    name: "Something that would surprise you if it were sold in a vending machine",
  },
  {
    id: 53,
    groupId: 8,
    name: "Special campaign to make young people want to go to the polls",
  },
  {
    id: 54,
    groupId: 6,
    name: "Something you want to do when you go to a resort island",
  },
  {
    id: 55,
    groupId: 8,
    name: "Something that makes you say “What!?” at a flea market",
  },
];

// { id: 1, groupId: 1, name: "Cool Job" },
// { id: 2, groupId: 1, name: "Stressful Job" },
// { id: 3, groupId: 1, name: "Position (Business)" },
// { id: 4, groupId: 1, name: "Something sad happens at office" },
// { id: 5, groupId: 1, name: "Troubles working at office" },
// { id: 6, groupId: 1, name: "Troubles working from home" },
// { id: 7, groupId: 1, name: "Troubles working with kids" },
// {
//   id: 8,
//   groupId: 1,
//   name: "Something happy in your bag",
// },
// { id: 9, groupId: 2, name: "Something happy" },
// { id: 10, groupId: 3, name: "Country known to kids" },
// { id: 11, groupId: 3, name: "Country size" },
// { id: 12, groupId: 3, name: "Country population" },
// { id: 13, groupId: 6, name: "Something cool" },
// { id: 14, groupId: 6, name: "Something cute" },
// { id: 15, groupId: 6, name: "Something fun" },
// { id: 16, groupId: 3, name: "Animal you want to be" },
// { id: 17, groupId: 6, name: "Creature you want to be" },
// { id: 18, groupId: 7, name: "Rare foods" },
// { id: 19, groupId: 7, name: "Foods kids hate" },

export const themeListJP = [
  { id: 1, groupId: 1, name: "モテる職業" },
  { id: 2, groupId: 3, name: "大きな生き物" },
  { id: 3, groupId: 5, name: "かっこいい苗字" },
  { id: 4, groupId: 5, name: "かっこいいもの" },
  { id: 5, groupId: 5, name: "可愛いもの" },
  { id: 6, groupId: 7, name: "かっこいい台詞" },
  { id: 7, groupId: 5, name: "死亡フラグ" },
  {
    id: 8,
    groupId: 2,
    name: "リモートワークの生産性が上がる、未来のテクノロジー",
  },
  { id: 9, groupId: 4, name: "お酒が進むおつまみ" },
  { id: 10, groupId: 3, name: "可愛い動物" },
  { id: 11, groupId: 8, name: "結婚したい有名人" },
  { id: 12, groupId: 5, name: "サンタがプレゼントしてくれたらうれしいもの" },
  { id: 13, groupId: 4, name: "手土産でうれしいお菓子" },
  { id: 14, groupId: 4, name: "おいしいお菓子" },
  { id: 15, groupId: 5, name: "無人島に一つだけ持って行けるもの" },
  { id: 16, groupId: 5, name: "無人島に連れていきたい有名人" },
  { id: 17, groupId: 6, name: "旅行したい国" },
  { id: 18, groupId: 4, name: "パンに入ってたらうれしい具材" },
  { id: 19, groupId: 4, name: "炊き込みご飯の人気具材" },
  { id: 20, groupId: 2, name: "メタバースでできたら嬉しいこと" },
  { id: 21, groupId: 8, name: "ジェダイのフォースでやりたいこと" },
  { id: 22, groupId: 4, name: "テンションの上がる今晩のおかず" },
  { id: 23, groupId: 8, name: "生まれ変わったらなりたい有名人" },
  { id: 24, groupId: 3, name: "生まれ変わったらなりたい動物" },
  { id: 25, groupId: 3, name: "変身出来たらうれしい動物" },
  { id: 26, groupId: 6, name: "日本に旅行したらやりたいこと" },
  { id: 27, groupId: 6, name: "ハワイに旅行したらやりたいこと" },
  { id: 28, groupId: 2, name: "アレクサができるようになったら嬉しいスキル" },
  { id: 29, groupId: 8, name: "遭遇したら怖い出来事" },
  { id: 30, groupId: 5, name: "リラックス効果のあるもの" },
  { id: 31, groupId: 5, name: "親からカミングアウトされたら驚くこと" },
  { id: 32, groupId: 4, name: "酸っぱい食べ物" },
  { id: 33, groupId: 8, name: "明日Amazonから届くわくわくするもの" },
  { id: 34, groupId: 1, name: "持ってたらドヤ顔できる資格やスキル" },
  { id: 35, groupId: 1, name: "どこでも転職できるとしたら転職したい企業" },
  { id: 36, groupId: 1, name: "子供のころなりたかった職業" },
  { id: 37, groupId: 7, name: "ゾンビを倒すときに役立つもの" },
  { id: 38, groupId: 7, name: "強いアニメキャラ" },
  { id: 39, groupId: 7, name: "かっこいい名前" },
  { id: 40, groupId: 6, name: "砂漠にもっていくと便利なもの" },
  { id: 41, groupId: 6, name: "北極にもっていくと便利なもの" },
  { id: 42, groupId: 5, name: "３文字の強いもの" },
  { id: 43, groupId: 5, name: "3文字のこわいもの・こと" },
  { id: 44, groupId: 5, name: "4文字のかわいいもの" },
  { id: 45, groupId: 5, name: "5文字のうれしいもの・こと" },
  { id: 46, groupId: 8, name: "玄関を開けた時にあったらびっくりするもの" },
  { id: 47, groupId: 1, name: "この人仕事ができそう！と思わせる特徴" },
  { id: 48, groupId: 2, name: "iPhone 99 に搭載された新機能" },
  { id: 49, groupId: 5, name: "大泥棒が盗んだとんでもないもの" },
  {
    id: 50,
    groupId: 8,
    name: "住んでいるマンションの1階にあったらうれしいもの",
  },
  { id: 51, groupId: 2, name: "ロボット掃除機に搭載されたら嬉しい新機能" },
  { id: 52, groupId: 8, name: "自動販売機で売っていたら驚くもの" },
  { id: 53, groupId: 8, name: "若者も選挙に行きたくなる、特別キャンペーン" },
  { id: 54, groupId: 6, name: "沖縄に行ったらやりたいこと" },
  { id: 55, groupId: 8, name: "メルカリで見つけたら「えっ！」と思う出品物" },
];

export function getTheme(categoryIdList) {
  const cList = categoryIdList
    ? categoryIdList
    : categoryListJP.forEach((element) => {
        const list = [];
        list.push(element.id);
        return list;
      });
  console.log(cList);
  const groupId = cList[Math.floor(Math.random() * cList.length)];

  console.log(groupId);
  const tList = themeListJP.filter((t) => t.groupId === parseInt(groupId));

  console.log(tList);
  const theme = tList[Math.floor(Math.random() * tList.length)];

  console.log(theme);
  return theme;
}
