import React from "react";
import { Navbar, Container, Dropdown, Image } from "react-bootstrap";
import config from "../config.json";
import logo from "../images/logo-white.png";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(i18n.language);
  };

  return (
    <Navbar
      className="navbar"
      bg="dark"
      expand="lg"
      id="mainNav"
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} />
          {config.name}
        </Navbar.Brand>
        <Dropdown>
          <Dropdown.Toggle variant="dark" id="dropdown-basic" size="sm">
            Language
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => changeLanguage("ja")}>
              日本語
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage("en")}>
              English
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};

export default NavBar;
