import React, { useState } from "react";
import InputListItem from "./inputListItem";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../config/icons";
import { getIcons } from "./../config/icons";
import { useTranslation } from "react-i18next";

const randomNumbers = () => {
  let array = [];
  for (let i = 1; i < 101; i++) {
    array.push(i);
  }
  return array;
};

const shuffle = (hundred) => {
  let array = [...hundred];
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  console.log(array);
  return array;
};

const randomNumberList = shuffle(randomNumbers());

function AddMember({
  pastMemberList = [{ name: "", email: "", hidden: false }],
  onSubmit,
}) {
  const { t } = useTranslation();
  const [members, setMembers] = useState(
    pastMemberList ? pastMemberList : [{ name: "", email: "", hidden: false }]
  );
  const [memberList, setMemberList] = useState([]);
  const [button, setButton] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleAdd = () => {
    setMembers([...members, { name: "", email: "", hidden: false }]);
  };

  const handleDelete = (index) => {
    console.log(index);
    let newList = [...members];
    newList[index].hidden = true;
    setMembers(newList);
  };

  const handleReady = (member) => {
    console.log(member);
    const newList = [...memberList, member];
    setMemberList(newList);
    setButton(newList.length === members.filter((m) => !m.hidden).length);
  };

  const handleStart = () => {
    setShowModal(true);
  };

  const handleModal = () => {
    const iconList = getIcons(memberList.length);
    console.log(iconList);
    for (let i = 0; i < iconList.length; i++) {
      memberList[i]["icon"] = iconList[i];
    }
    console.log(memberList);
    sessionStorage.setItem("memberList", JSON.stringify(memberList));
    onSubmit(memberList);
  };

  return (
    <div className="text-center">
      <Modal show={showModal}>
        <Modal.Body>
          <p>{t("onlineModal")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleModal()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <h3 className="section-heading text-uppercase">
          {/* 参加者に「数字」を送りましょう */}
          {t("header1")}
        </h3>
        <h3 className="section-subheading text-muted">{t("subheader1")}</h3>
      </div>
      {members &&
        members.map((row, key) => (
          <InputListItem
            name={row.name}
            email={row.email}
            key={key}
            index={key}
            number={randomNumberList[key]}
            onReady={(member) => handleReady(member)}
            onDelete={(index) => handleDelete(index)}
            hidden={row.hidden}
          />
        ))}
      <Button
        variant="dark"
        size="sm"
        onClick={() => handleAdd()}
        className="mb-3"
      >
        <FontAwesomeIcon
          icon={icons.PLUS}
          size="1x"
          color="#fff"
          className="fa-fw"
        />
        Add
      </Button>
      <div>
        <Button
          variant="primary"
          size="lg"
          className="text-uppercase"
          onClick={() => handleStart()}
          disabled={!button}
        >
          Start
        </Button>
      </div>
    </div>
  );
}

export default AddMember;
